<template>
    <div id="contents" class="contents vmd_channel_page channel_add_page">
        <div class="content_head">
            <!-- s: 페이지 타이틀 -->
            <h2 class="page_ttl" v-if="pageMode === 'modify' && modifyChannel">{{modifyChannel.name}}</h2>
            <h2 v-else class="page_ttl">새 채널 만들기</h2>
            <!-- e: 페이지 타이틀 -->
        </div>

        <!-- s: content_body -->
        <div class="content_body">
            <!-- s: input_sec -->
            <div class="input_sec">
                <!-- s: table -->
                <div class="tbl_wrap list form">
                    <table class="tbl">
                        <caption></caption>
                        <colgroup>
                          <col width="200"/>
                          <col/>
                          <col width="200"/>
                          <col/>
                        </colgroup>
                        <tbody>
                        <tr v-if="pageMode === 'modify' && modifyChannel">
                            <th class="must_txt">등록 일시</th>
                            <td>
                                <p>{{modifyChannel.creationDateTime}}</p>
                            </td>
                            <th class="must_txt">수정 일시</th>
                            <td>
                                <p>{{modifyChannel.lastModifiedDateTime}}</p>
                            </td>
                        </tr>
                        <tr>
                            <th class="must_txt">채널명</th>
                            <td colspan="3">
                                <div :class="`input_area inline ${!channelName ? 'is_error' : ''}`">
                                    <label></label>
                                    <div class="input_box">
                                        <input type="text" v-model="channelName">
                                    </div>
                                    <small v-if="!channelName" class="error_txt">제품명을 입력 해 주세요.</small>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <!-- e: table -->
            </div>
            <div class="board_sec channel_list clearFix">
                <!-- s: table -->
                <div class="tbl_wrap list">
                    <div class="tbl_head">
                      <div class="btn_group taR">
                        <button class="btn basic h_s w_sm" @click="deleteVmd">삭제</button>
                        <button class="btn strong h_s w_sm" @click="vmdModal = true">VMD 추가</button>
                      </div>
                    </div>
                    <!-- 게시글 있는 경우 (10개씩 노출) -->
                    <div class="tbl_div">
                        <div class="tbl_row_group head">
                            <div class="tbl_row">
                                <div class="check">
                                    <div class="input_area checkbox">
                                        <div class="input_box">
                                            <label>
                                                <input type="checkbox" v-model="selectAll">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="order">
                                    <p>진열순위</p>
                                </div>
                                <div class="image">
                                    <p>이미지</p>
                                </div>
                                <div class="product">
                                    <p>제품명</p>
                                </div>
                                <div class="type">
                                    <p>템플릿 타입</p>
                                </div>
                                <div class="quantity">
                                    <p>재생 파일 수</p>
                                </div>
                                <div class="duration">
                                    <p>재생 시간</p>
                                </div>
                            </div>
                        </div>
                        <Container class="tbl_row_group body"
                                   orientation="vertical"
                                   @drop="onDrop"
                                   tag="div"
                                   :drop-placeholder="dropPlaceholderOptions">
                            <Draggable class="tbl_row" v-for="(item, index) in orderedVmd" :key="item.hash" tag="div">
                                <div class="check">
                                    <div class="input_area checkbox">
                                        <div class="input_box">
                                            <label>
                                                <input type="checkbox" :value="item.hash" v-model="checkedVmdInChannel">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="order">
                                    <span>{{item.order}}</span>
                                </div>
                                <div class="image">
                                    <div class="img_box">
                                        <ImageView :src="getFirstFile(item)"/>
                                    </div>
                                    <!--                                    <img src="/kt/resources/img/test.jpg" alt="">-->
                                </div>
                                <div class="product">
                                    <span>{{item.name}}</span>
                                </div>
                                <div class="type">
                                    <span>{{getTemplateName(item.templateType)}}</span>
                                </div>
                                <div class="quantity">
                                    <span>{{item.files ? item.files.length : 0}}</span>
                                </div>
                                <div class="duration">
                                    <span>{{item.getFormattedPlayTime()}}</span>
                                </div>
                            </Draggable>
                        </Container>
                        <!-- 등록된 채널이 없을 경우 -->
                        <!--                        <tbody class="noVMD">
                                                    <tr>
                                                        <td colspan="7">
                                                            등록된 VMD가 없습니다.
                                                            </td>
                                                       </tr>
                                                </tbody>-->
                    </div>
                </div>
                <!-- e: table -->
            </div>
            <!-- e: board_sec -->
        </div>
        <!-- e: content_body -->

        <!-- s: content_foot -->
        <div class="content_foot">
            <div class="star_txt">*생성된 채널은 삭제할 수 없습니다. 신중하게 추가해 주시기 바랍니다.</div>
            <div class="btn_group center">
                <button class="btn basic w_l h_m" @click="redirect('/channel')">취소</button>
                <button class="btn strong w_l h_m" v-if="!pageMode" @click="create">저장</button>
                <button class="btn strong w_l h_m" v-if="pageMode === 'modify'" @click="modify">수정</button>
            </div>
        </div>
        <!-- e: content_foot -->

        <!-- s: VMD add MODAL -->
        <div id="modal" :class="`modal_wrap ${vmdModal ? 'is_show' : ''}`">
            <div class="modal">
                <div class="modal_head">
                    <div class="close_modal" @click="closeModal()"></div>
                </div>
                <div class="modal_body">
                    <VMDIndex ref="vmdModal" :is-modal="true" :modifyHash="modifyHash" v-on:update-checked-items="(obj) => updateCheckedItems(obj)"/>
                </div>
                <div class="modal_foot">
                    <div class="btn_area">
                        <button class="btn strong h_l" @click="selectVmd">선택</button>
                        <button class="btn basic h_l" @click="closeModal()">닫기</button>
                    </div>
                </div>
            </div>
            <!-- e: VMD add MODAL -->
        </div>
        <!-- e: VMD add MODAL -->
    </div>
</template>

<script>
import VMDIndex from '@/pages/firebase/vmd/index'
import {Container, Draggable} from "vue3-smooth-dnd";
import {applyDrag} from "@/plugins/dnd-helpers";
import {getTemplateName, TEMPLATE} from "@/plugins/firebase/type";
import ImageView from "@/components/imageView";
import Channel from "@/plugins/firebase/model/channel";
import moment from "moment";
import HotDeal from "@/plugins/firebase/model/vmd/hotdeal";
import HalfPrice from "@/plugins/firebase/model/vmd/halfprice";
import Explore from "@/plugins/firebase/model/vmd/explore";

export default {
    name: "create",
    components: {
        VMDIndex: VMDIndex,
        ImageView: ImageView,
        Container: Container,
        Draggable: Draggable,
    },
    layout: {
        name: 'admin',
    },
    computed: {
        orderedVmd() {
            return this.selectedVmd.sort((a, b) => {
                return a.order - b.order;
            });
        },
        selectAll: {
            get: function () {
                return this.checkedVmd ? this.checkedVmdInChannel.length === this.checkedVmd.length : false;
            },
            set: function (value) {
                let selected = [];
                if (value) {
                    this.checkedVmd.forEach((item) => {
                        selected.push(item.hash);
                    });
                }
                this.checkedVmdInChannel = selected;
            }
        },
    },
    data() {
        return {
            vmd_root_key: 'vmd',
            channel_root_key: 'channel',
            vmdModal: false,
            channelName: '',
            checkedVmdInChannel: [],
            checkedVmd: [],
            selectedVmd: [],
            dropPlaceholderOptions: {
                className: 'drop-preview',
                animationDuration: '150',
                showOnTop: true
            },
            pageMode: '',
            modifyHash: '',
            modifyChannel: '',
        }
    },
    methods: {
        updateCheckedItems(obj) {
            this.checkedVmd = obj.total;
        },
        selectVmd() {
            this.selectedVmd = [];
            this.checkedVmd.forEach((item) => {
                let find = this.selectedVmd.find((exist) => exist.hash === item.hash);
                if(!find){
                    this.selectedVmd.push(item);
                }
            });

            if(this.selectedVmd.length > 5){
                this.selectedVmd = [];
                alert('최대 5개까지만 선택 가능합니다.');
                return;
            }

            // 순서 매기기 로직
            let vmdLength = this.selectedVmd.length + 1;
            let fullNo = [...Array(vmdLength).keys()].map(i => i + 1);
            let existsNo = this.selectedVmd.map((item) => item.order);
            let differ = fullNo.filter(x => !existsNo.includes(x));
            this.selectedVmd.forEach((item, index) => {
                if(!item.order){
                    let min = Math.min.apply(null, differ);
                    item.order = min;
                    differ = differ.filter(x => x !== min);
                }
            });

            this.closeModal();
        },
        getFirstFile(vmd) {
            if (vmd && vmd.files && vmd.files.length > 0) {
                return vmd.files[0];
            }
            return null;
        },
        getTemplateName(code) {
            return getTemplateName(code);
        },
        create() {
            if (!this.channelName)
                return;

            let channel = new Channel();
            this.$firebase.database().ref(this.channel_root_key).once('value', async (sn) => {
                let newOrder = 1;
                let data = sn.val();
                if (data) {
                    newOrder = Object.keys(sn.val()).length + 1;
                }

                channel.name = this.channelName;
                channel.no = newOrder;
                channel.creationDateTime = this.timeToString(moment());
                channel.lastModifiedDateTime = this.timeToString(moment());
                channel.vmd = this.selectedVmd.map((item) => {
                    return item.hash;
                });
                if (this.selectedVmd) {
                    for await (const [index, item] of this.selectedVmd.entries()) {
                        await this.$firebase.database().ref(this.vmd_root_key).child(item.hash).set(item);
                    }
                }

                this.$firebase.database().ref(this.channel_root_key).push(channel);
                this.toastSuccess('등록 완료.');
                this.redirect('/channel');
            });

        },
        async modify() {
            if (!this.channelName)
                return;

            let channel = new Channel(this.modifyChannel);
            channel.name = this.channelName;
            channel.lastModifiedDateTime = this.timeToString(moment());
            channel.vmd = this.selectedVmd.map((item) => {
                return item.hash;
            });
            if (this.selectedVmd) {
                for await (const [index, item] of this.selectedVmd.entries()) {
                    await this.$firebase.database().ref(this.vmd_root_key).child(item.hash).set(item);
                }
            }
            this.$firebase.database().ref(this.channel_root_key).child(this.modifyHash).set(channel).then(() => {
                this.toastSuccess('수정 완료.');
                this.redirect('/channel');
            });
        },
        getGhostParent() {
            return document.body;
        },
        onDrop(dropResult) {
            if (dropResult.removedIndex !== null && dropResult.addedIndex !== null) {
                this.selectedVmd[dropResult.removedIndex].order = dropResult.addedIndex + 1;
                this.selectedVmd[dropResult.addedIndex].order = dropResult.removedIndex + 1;
            }

            this.selectedVmd = applyDrag(this.selectedVmd, dropResult).sort((a, b) => {
                return a.order - b.order;
            });
        },
        deleteVmd() {
            this.checkedVmd = this.checkedVmd.filter((item) => {
                if (this.checkedVmdInChannel.includes(item.hash.toString())) {
                    delete item.order;
                    return false;
                }
                return true;
            });
            this.selectVmd();
        },
        closeModal(){
            if(this.$refs.vmdModal){
                this.$refs.vmdModal.clearCheckes();
            }
            this.vmdModal = false;
        },
        vmdInstance(obj) {
            let vmd;
            switch (obj.templateType) {
                case TEMPLATE.HOT_DEAL.code:
                    vmd = new HotDeal(obj);
                    break;
                case TEMPLATE.HALF_PRICE.code:
                    vmd = new HalfPrice(obj);
                    break;
                case TEMPLATE.EXPLORE.code:
                    vmd = new Explore(obj);
                    break;
                default:
                    break;
            }
            return vmd;
        },
    },
    created() {
        this.checkedVmd = [];
        if (this.$route.meta.mode) {
            this.pageMode = this.$route.meta.mode;
            this.modifyHash = this.$route.params.hash;
            this.$firebase.database().ref(this.channel_root_key).child(this.modifyHash).once('value', (sn) => {
                let channelSnapShot = sn.val();
                if (channelSnapShot) {
                    this.modifyChannel = channelSnapShot;
                    this.channelName = this.modifyChannel.name;
                    this.$firebase.database().ref(this.vmd_root_key).once('value', async (sn) => {
                        let vmdSnapShot = sn.val();
                        if (vmdSnapShot) {
                            for (const [vmdHash, vmd] of Object.entries(vmdSnapShot)) {
                                if (channelSnapShot.vmd?.includes(vmdHash)) {
                                    let newVmd = this.vmdInstance(vmd);
                                    newVmd.hash = vmdHash;
                                    this.checkedVmd.push(newVmd);
                                }
                            }
                            this.selectVmd();
                        }
                    });
                }
            });
        }
    }
}
</script>

<style scoped>
table.tbl tbody tr:first-child {
    padding-top: 50px;
}

table.tbl tbody tr:last-child {
    padding-bottom: 50px;
}
</style>